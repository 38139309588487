import httpService from '../../../helpers/http.service'

export default {

    get(id = null, params = {}) {
        return httpService.get(`liquidations-validate/commissions${id ? '/' + id : '' }`, {params})
    },
    save(params) {
        return httpService.post(`liquidations-validate/commissions/generate`, params);
    },
    changeStatus(id) {
        return httpService.post(`liquidations-validate/status/${id}`)
    },
    revertStatus(id, observation) {
        return httpService.post(`liquidations-validate/revert-status/${id}`,{
            'observation_revert': observation
        })
    },
    getPDF(id){
        return httpService.get(`reports/liquidations/${id}/export`)
    },

}
